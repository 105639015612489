.container-platform-common {
    background-color: #0c0c0c;
    text-align: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    color: #8c8c8c;
    border: 8px solid orange;
    width: 100%;
    z-index: 1000;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.container-platform .loading-ghost {
    filter: blur(2rem);
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.container-platform .loading-ghost.item-img {
    filter: blur(2rem);
}

.platform-modale-card .item-img::part(image) {
    border-radius: 5px;
}

.container-platform .item-card {
    border: 1px solid #464646;
    margin-inline: 0;
    border-radius: 0;
    margin-top: 6px;
    margin-bottom: 0;
}

.container-platform ion-card .header {
    display: flex;
    align-items: center;
    background: #262626;
}

.container-platform ion-card .picto {
    padding: 10px 10px 5px 10px;
    cursor: pointer;
}

.container-platform ion-card .options {
    padding-top: 10px;
}

.container-platform ion-card .options .item-comment {
    font-weight: bold;
}

.platform-modale-content ion-card .nospace-comments {
    margin: 0;
    display: flex;
    align-items: center;
}

.platform-modale-content ion-card .align-comment-number {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.platform-modale-content ion-card .align-comment-number ion-icon {
    margin-left: 2px;
}

.container-platform ion-card .options .item-comment span {
    margin: 0;
}

.container-platform ion-card .options .avatar-number {
    font-size: 25px;
    margin: 0;
    line-height: 25px;
    width: 100%;
}

.container-platform ion-card .options .icon-dialog {
    margin: 0;
}

.container-platform ion-card .comments {
    padding-left: 10px;
    padding-bottom: 10px;
}

.container-platform ion-card .item-content {
    padding: 5px 0 0 0;
}

.container-platform ion-card .description {
    padding: 10px;
    height: 75px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.container-platform .item-content .item-description {
    max-height: 100px;
    overflow: hidden;
    cursor: pointer;
}

.container-platform ion-card .url .text {
    padding: 10px;
    background-color: #343434;
    border-radius: 5px;
    overflow-y: hidden;
    margin-left: 5px;
    cursor: pointer;
}

.container-platform .url .text {
    max-height: 65px;
    max-width: 100%;
    overflow-y: hidden;
}

.container-platform .url .text:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(30 30 30) 90%); */
    width: 100%;
    height: 2em;
}

.container-platform .item-img::part(image) {
    border-radius: 5px;
}

.container-platform .search-bar {
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #1f1f1f;
}

.web .container-platform .search-bar {
    max-width: 500px;
}

.container-platform .search-bar .search-bar-img {
    margin-top: 2px;
    margin-left: 4px;
    height: 52px;
    width: 100%;
    border-radius: 3px;
}

.platform-modale-content {
    width: 100%;
    max-width: 100%;
}

.platform-modale-content .thumb {
    width: 100%;
    max-width: 100%;
}

.container-platform .platform-modale-content .thumb::part(image) {
    object-fit: fill;
    object-position: top;
    height: 311px;
}

.modale-header,
.modale-footer,
.platform-modale-content,
.comment-modale-header,
.comment-modale-content {
    display: block;
    position: absolute;
}

.modale-footer {
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
}

.platform-modale-content,
.comment-modale-content {
    top: 50px;
}

.platform-modale-adding {
    height: calc(100% - 104px);
}

#modale-add-content::part(content),
#modale::part(content),
#comment-modale::part(content) {
    background-color: black;
    height: 100vh;
}

.platform-modale-card {
    border-radius: 5px;
    margin: 5px 0 15px 0;
    max-height: calc(100vh - 56px);
    overflow: scroll;
    overflow-x: hidden;
}

.platform-modale-card .header .title {
    text-align: center;
}

.platform-modale-card .url {
    padding: 0;
}

.platform-modale-card .ad-img {
    width: 100%;
    height: 115px;
}

.platform-modale-card .ad-img.blurred {
    filter: blur(5px);
}

.platform-modale-card .modale-comment-title {
    padding-left: 5px;
    text-align: left;
}

.platform-modale-card .item-url {
    padding-left: 5px;
}

.platform-modale-card .comment-block {
    margin-bottom: 25px;
}

.platform-modale-card .comment-block .align-flex {
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
}

.platform-modale-card .comment-block ion-range {
    padding: 0;
}

.platform-modale-card .comment-block .browse-file[type="file"] {
    display: none;
}

.platform-modale-card .comment-block .custom-file-upload {
    background-color: rgb(133, 231, 133);
    color: black;
    height: 2.1em;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin: 4px 2px;
    padding: 6px 12px;
    border-radius: 4px;
    position: relative;
    display: inline-block;
    width: 110px;
}

.platform-modale-card .comment-block .custom-file-upload .custom-file-libelle {
    position: absolute;
    right: 10px;
    top: 6px;
}

.platform-modale-card .comment-block .custom-file-icon {
    font-size: 1.4em;
    position: absolute;
    left: 10px;
    top: 4px;
}

.platform-modale-card .comment-block .comment-thumb {
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.platform-modale-card .comment-block .comment-block-input {
    width: 100%;
    border: 1px solid #2dd36f;
    min-height: 100px;
    border-radius: 5px;
}

.platform-modale-card .comment-block .comment-block-input ion-textarea .textarea-wrapper .native-textarea {
    padding: 0 5px;
    height: 80px;
}

.platform-modale-card .comment-block .comment-block-button ion-button {
    color: black;
}

.platform-modale-card .comment-block .comment-block-button {
    width: 100%;
}


/** positionnement commentaire **/

.platform-modale-card .star-comment .avatar-comment {
    height: 32px;
    width: 32px;
    margin-top: 5px;
}

.platform-modale-card .star-comment .header-comment ion-col {
    padding-top: 0;
    padding-bottom: 0;
}

.platform-modale-card .star-comment .body-comment ion-col {
    padding-top: 0;
}


/** fin positionnement commentaire **/

#nsa-list {
    background-color: black;
}

.item-text-stars {
    border-bottom: 1px solid rgb(133, 231, 133);
}