.container-subscription-common {
    background-color: #0c0c0c;
    text-align: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    color: #8c8c8c;
    border: 8px solid orange;
    width: 100%;
    z-index: 1000;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.container-subscription .loading-ghost {
    filter: blur(2rem);
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.container-subscription .loading-ghost.item-img {
    filter: blur(2rem);
}

.subs-modale-card .item-img::part(image) {
    border-radius: 5px;
}

.container-subscription .item-img::part(image) {
    border-radius: 5px;
}

.container-subscription .item-content .item-description {
    max-height: 80px;
}

.container-subscription .url .item-text:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(30 30 30) 90%);
    width: 100%;
    height: 2em;
}

.container-subscription .url .item-text {
    max-height: 65px;
    max-width: calc(100% - 60px);
    overflow-y: hidden;
}

.container-subscription .url .text {
    padding: 10px;
    background-color: #343434;
    border-radius: 5px;
    overflow-y: hidden;
    margin-left: 5px;
    cursor: pointer;
}

.container-subscription .item-card {
    border: 1px solid #464646;
    margin-inline: 0;
    border-radius: 0;
    margin-top: 6px;
    margin-bottom: 0;
}

.container-subscription ion-card .header {
    display: flex;
    align-items: center;
    background: #262626;
}

.container-subscription ion-card .picto {
    padding: 10px 10px 5px 10px;
    cursor: pointer;
}

.container-subscription ion-card .options {
    padding-top: 10px;
}

.container-subscription ion-card .options .item-comment {
    font-weight: bold;
}

.modale-content ion-card .nospace-comments {
    margin: 0;
    display: flex;
    align-items: center;
}

.modale-content ion-card .align-comment-number {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.modale-content ion-card .align-comment-number ion-icon {
    margin-left: 2px;
}

.container-subscription ion-card .options .item-comment span {
    margin: 0;
}

.container-subscription ion-card .options .avatar-number {
    font-size: 25px;
    margin: 0;
    line-height: 25px;
    width: 100%;
}

.container-subscription ion-card .options .icon-dialog {
    margin: 0;
}

.container-subscription ion-card .comments {
    padding-left: 10px;
    padding-bottom: 10px;
}

.container-subscription ion-card .item-content {
    padding: 0 10px 10px;
}

.container-subscription ion-card .description {
    padding: 10px;
    height: 75px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.container-subscription .item-content .item-description {
    max-height: 100px;
    overflow: hidden;
    cursor: pointer;
}

.container-subscription ion-card .url .text {
    padding: 10px;
    background-color: #343434;
    border-radius: 5px;
    overflow-y: hidden;
    margin-left: 5px;
}


/** barre de recherche **/

.container-subscription .search-bar {
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #1f1f1f;
}

.container-subscription .search-bar .subscription-list {
    margin: auto;
    display: flex;
}

.container-subscription .search-bar .subscription-list-empty {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-self: center;
    align-content: center;
}

.container-subscription .search-bar .full-scroll {
    overflow-x: scroll;
}


/** fin barre recherche **/

.container-subscription .no-subs-message {
    text-align: center;
    color: #8c8c8c;
    background-color: #343434;
    border-radius: 40px;
    margin: 90px 10px 0;
    padding: 10px 20px;
}

.web .container-subscription .search-bar {
    max-width: 500px;
}

.container-subscription .search-bar .search-bar-img {
    margin-top: 2px;
    margin-left: 4px;
    height: 52px;
    width: 100%;
    border-radius: 3px;
}

.modale-content {
    width: 100%;
    max-width: 100%;
}

.modale-content .thumb {
    width: 100%;
    max-width: 100%;
}

.container-subscription .modale-content .thumb::part(image) {
    object-fit: cover;
    object-position: top;
    height: 311px;
}

.modale-header,
.modale-content,
.comment-modale-header,
.comment-modale-content {
    display: block;
    position: absolute;
}

.modale-nebula ion-card {
    height: calc(100vh - 70px);
}

.modale-content,
.comment-modale-content {
    top: 50px;
}

#modale-add-content::part(content),
#modale::part(content),
#comment-modale::part(content) {
    background-color: black;
    height: 100vh;
}

.subs-modale-card {
    border-radius: 5px;
    margin: 5px 0 15px 0;
    max-height: calc(100vh - 56px);
    overflow: scroll;
    overflow-x: hidden;
}

.subs-modale-card .url {
    padding: 0;
}

.subs-modale-card .ad-img {
    width: 100%;
    height: 115px;
}

.subs-modale-card .ad-img.blurred {
    filter: blur(5px);
}

.subs-modale-card .modale-comment-title {
    padding-left: 5px;
    text-align: left;
}

.subs-modale-card .item-url {
    padding-left: 5px;
}

.subs-modale-card .comment-block {
    margin-bottom: 25px;
}

.subs-modale-card .comment-block .browse-file[type="file"] {
    display: none;
}

.subs-modale-card .comment-block .custom-file-upload {
    background-color: rgb(133, 231, 133);
    color: black;
    height: 2.1em;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin: 4px 2px;
    padding: 6px 12px;
    border-radius: 4px;
    position: relative;
    display: inline-block;
}

.subs-modale-card .comment-block .custom-file-upload .custom-file-libelle {
    margin-left: 18px;
}

.subs-modale-card .comment-block .custom-file-icon {
    font-size: 1.4em;
    pointer-events: none;
    margin: 0px 0.3em 0px -0.3em;
    margin-inline: -0.3em 0.3em;
    position: absolute;
    top: 5px;
}

.subs-modale-card .comment-block .comment-thumb {
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.subs-modale-card .comment-block .comment-block-input {
    width: 100%;
    border: 1px solid #2dd36f;
    min-height: 100px;
    border-radius: 5px;
}

.subs-modale-card .comment-block .comment-block-input ion-textarea .textarea-wrapper .native-textarea {
    padding: 0 5px;
    height: 8vh;
}

.subs-modale-card .comment-block .comment-block-button ion-button {
    color: black;
}

.subs-modale-card .comment-block .comment-block-button {
    width: 100%;
}


/** positionnement commentaire **/

.subs-modale-card .star-comment .avatar-comment {
    height: 32px;
    width: 32px;
    margin-top: 5px;
}

.subs-modale-card .star-comment .header-comment ion-col {
    padding-top: 0;
    padding-bottom: 0;
}

.subs-modale-card .star-comment .body-comment ion-col {
    padding-top: 0;
}


/** fin positionnement commentaire **/

#nsa-list {
    background-color: black;
}

.item-text-stars {
    border-bottom: 1px solid rgb(133, 231, 133);
}