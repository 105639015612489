/* CONTENEUR */

.container-market {
    background-color: black;
    /* position: absolute; */
    left: 0;
    right: 0;
    height: calc(100% - 80px);
    min-height: calc(100vh - 80px);
}

.container-market .filter-bar {
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #1f1f1f;
}

.web .container-market .filter-bar {
    max-width: 500px;
}

.container-market .filter-bar .filter-bar-img {
    margin-top: 2px;
    margin-left: 4px;
    height: 52px;
    width: 100%;
    border-radius: 3px;
}

.container-market .top-side .half-group {
    display: inline-block;
    width: 50%;
}

.container-market .top-side .label,
.container-market .bottom-side .label {
    margin-left: 15px;
}

.container-market .top-side .buy-sell-button,
.container-market .top-side .buy,
.container-market .top-side .sell {
    border: 2px solid;
    border-radius: 3px;
    margin-bottom: 15px;
    width: 46%;
}

.container-market .top-side .buy {
    margin-left: 3%;
    margin-right: 1%;
}

.container-market .top-side .sell {
    margin-right: 3%;
    margin-left: 1%;
}

.container-market .top-side .buy-sell-button {
    width: 100%;
    margin: 0;
}

.container-market .top-side .buy-sell-button.buy,
.container-market .top-side .buy {
    border-color: green;
}

.container-market .top-side .buy-sell-button.sell,
.container-market .top-side .sell {
    border-color: red;
}

.container-market .bottom-side {
    margin-top: 15px;
}

.container-market .bottom-side ion-item-group {
    display: flex;
}

.container-market .bottom-side ion-item-group .half-group {
    width: 50%;
}

.container-market ion-card-title {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}

.container-market ion-card-content {
    height: 78px;
}

.container-market .mosaic {
    height: calc(100% - 78px);
    outline: none;
    overflow-y: auto;
    position: relative;
    margin-top: 78px;
}

.container-market .card-item {
    box-shadow: none;
    margin: 0;
}

.container-market .nft-img {
    width: 100%;
}

.container-market-alert {
    background-color: #0c0c0c;
    text-align: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    color: #8c8c8c;
    border: 8px solid orange;
    width: 100%;
    z-index: 1000;
}


/* CONTENEUR COMMON */

.container-market-common {
    background-color: rgb(34, 34, 34);
    padding: 15px 8px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 100%;
    padding-bottom: 10vh;
    margin: 40px 8px 0;
}

.container-market-common ion-list {
    height: 82vh;
    overflow: scroll;
}

.container-market-dev {
    background-color: #0c0c0c;
    text-align: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    color: #8c8c8c;
    border: 8px solid orange;
    z-index: 1;
    width: 100%;
}

.container-market-tab {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
}

.container-market-tab ion-button {
    width: 50vw;
}

.container-market #nft-list {
    background: black;
}

.container-market .nft-item {
    border-radius: 5px;
}

.container-market .nft-item .card-item {
    padding-top: 15px;
}

.container-market .nft-item .card-header {
    padding: 15px 0;
}

.container-market .nft-item .card-buttons {
    padding-bottom: 15px;
}

.container-market .nft-item .card-buttons ion-col {
    padding: 0;
}

.container-market .nft-item .card-buttons ion-col .button-small:nth-child(1) {
    width: 100%;
    padding: 0 5px 0 0;
}

.container-market .nft-item .card-buttons ion-col .button-small:nth-child(2) {
    width: 100%;
    padding: 0 0 0 5px;
}

.container-market .nft-item .card-title,
.container-market .nft-item .card-collection {
    height: 18px;
}

.container-market .nft-item .card-subtitle {
    text-transform: uppercase;
}

.container-market .nft-item .card-price,
.container-market .nft-item .card-upgrades {
    height: 32px;
    padding: 0;
}


/* Loading ghost */

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.loading-ghost {
    background-color: #616161;
    color: #616161;
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.loading-ghost>img {
    filter: blur(2rem);
}

.loading-ghost>.text {
    filter: blur(2rem);
}

ion-modal#filter-modal {
    --height: 270px;
    --width: 80%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    max-height: 100%;
}

ion-modal#filter-buy-modal {
    --height: 480px;
    --width: 80%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    max-height: 100%;
}

ion-modal#filter-buy-modal .break-words {
    word-break: break-all;
    word-wrap: break-word;
}

ion-modal#filter-buy-modal::part(backdrop),
ion-modal#filter-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#filter-buy-modal ion-toolbar,
ion-modal#filter-modal ion-toolbar {
    --background: rgb(133, 231, 133);
    --color: black;
}

ion-modal#filter-modal ion-item {
    cursor: pointer;
}

ion-modal#filter-buy-modal ion-button.full-size {
    width: 100%;
}

ion-modal#filter-buy-modal ion-grid.no-margin,
ion-modal#filter-buy-modal ion-label.no-margin {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

ion-modal#filter-buy-modal ion-label.no-margin p.full-text {
    overflow: unset;
    word-wrap: break-word;
    white-space: normal;
    text-align: justify;
    font-style: italic;
}

ion-modal#filter-buy-modal img.nft-illustration {
    width: 70%;
    display: flex;
    margin: auto;
}

.container-market .filter-bar .full-scroll {
    overflow-x: scroll;
}

.container-market #open-filter::part(native) {
    padding-inline-start: unset;
}