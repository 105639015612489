
/* CONTAINER */
.container-register {
  text-align: left;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  transform: translateY(-50px);
  background-color: white;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  bottom: 0;
}

.container-register h1 {
  font-size: 25px;
  line-height: 26px;
  
}

.container-register p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container-register a {
  text-decoration: none;
  color: black;
}

.container-register .register-button {
  color: rgb(133, 231, 133);
}

.container-register .forgot-password {
  text-align: right;
  margin: 6px 2px 2px 2px;
}

.container-register .register-login {
  text-align: center;
  margin-top: 4px;
}

.container-register ion-item {
  border-radius: 10px;
  margin: 6px 2px 2px 2px;
  height: 42px
}

/* HEADER */

.header-register {
  background-color: black;
}

.header-register h1 {
  font-size: 60px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
}

.header-register h2 {
  font-size: 11px;
  text-align: center;
  margin-top: 0;
}