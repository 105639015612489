.header-collapse-condense ion-toolbar ion-searchbar {
    width: 284px;
    right: 0;
    position: absolute;
}


/* CONTENEUR AIRDROP */

.container-wallet {
    background-color: black;
    margin-top: 100px;
}

.container-wallet strong {
    font-size: 20px;
    line-height: 26px;
}

.container-wallet p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

.container-wallet a {
    text-decoration: none;
}


/* CONTENEUR COMMON */

.container-wallet-common {
    position: relative;
    background-color: rgb(34, 34, 34);
    padding: 15px 8px 10vh;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    min-height: 110vh;
    height: 100%;
    padding-bottom: 10vh;
    margin: 0 8px;
    margin-top: 40px;
}

.container-wallet-common .position-icon-eye::part(native),
.container-wallet-common .position-icon-refresh::part(native) {
    padding: 0;
    width: 36px;
    height: 26px
}

.container-wallet-common .position-icon-eye,
.container-wallet-common .position-icon-refresh {
    position: absolute;
}

.container-wallet-common .position-icon-eye {
    right: 52px;
    top: 2px;
    font-size: 22px;
}

.container-wallet-common .position-icon-refresh {
    right: 12px;
    top: 2px;
    font-size: 18px;
}

.container-wallet-common .compteur-text {
    margin: 0 15px;
    font-size: 14px;
}

.container-wallet-common .compteur-valeur {
    margin: 0 15px;
    font-size: 24px;
}

.container-wallet-common .compteur-valeur #compteur-value-nsa,
.container-wallet-common .compteur-valeur #compteur-value-nsan {
    font-weight: bold;
}

.container-wallet-common .compteur-adresse {
    margin: 10px 0 5px;
    font-size: 12px;
    display: table;
    width: 100%;
    border-spacing: 5px;
}

.container-wallet-common .compteur-adresse.color-black {
    color: black;
}

.container-wallet-common .compteur-adresse ion-button {
    display: table-cell;
    font-size: 11px;
}

.container-wallet-common .compteur-adresse .pointer {
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}

.container-wallet-common .compteur-adresse span {
    display: table-cell;
}

.container-wallet-common .compteur-hash {
    margin: 10px 15px;
    font-size: 11px;
    line-height: 1;
}

.container-wallet-common .title {
    margin: 2rem 15px 5px;
    line-height: 1;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.container-wallet-common .pubkey-hash {
    margin: 0 15px 2rem;
    font-size: 12px;
    line-height: 1;
    text-align: center;
}

.container-wallet-common .common-buttons {
    text-align: center;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
}

.container-wallet-common .common-buttons ion-card {
    margin: 0 3px;
    padding: 0 2px;
    display: inline-block;
    width: 15%;
    max-width: 90px;
    height: 56px;
    background-color: rgb(44, 44, 44);
}

.container-wallet-common .common-buttons ion-card ion-icon {
    padding-top: 8px;
    font-size: 20px;
}

.container-wallet-common .common-buttons ion-card ion-card-header {
    padding: 4px 0 0 0;
}

.container-wallet-common .common-buttons ion-card ion-card-subtitle {
    font-size: 12px;
}

.container-wallet-common .common-history {
    margin: 15px 5px 0 5px;
    max-height: 70vh;
    overflow-y: hidden;
    background-color: rgb(44, 44, 44);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.container-wallet-common .common-history .tiny-link {
    font-size: 10px;
    margin-left: 10px;
}

.container-wallet-common .common-history .align-right {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
}

.container-wallet-common .common-history .title {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
}

.container-wallet-common .buy,
.container-wallet-common .send,
.container-wallet-common .deposit {
    margin: 10px 5px;
    padding: 10px;
    background-color: rgb(44, 44, 44);
    border-radius: 15px;
}

.external-wallet {
    display: flex;
    justify-content: space-around;
    margin-top: 18px;
    margin-bottom: 10px;
}

.wallet-adapter-dropdown-list {
    left: 0;
    width: 250px;
}

.wallet-adapter-modal-wrapper.wallet-adapter-modal-wrapper-no-logo {
    background-color: #212121;
    color: #afafaf;
    border-radius: 30px;
}

.wallet-adapter-modal-container .wallet-adapter-botton-modal {
    width: 100%;
    margin-bottom: 10px;
}

.modal-item {
    margin: 1rem 1rem 0 1rem;
}

.modal-item.close {
    position: absolute;
    right: 0;
    top: 0;
}

.modal-text {
    margin: 1rem 1rem 0 1rem;
    font-size: 14px;
}

.modal-title {
    margin: 1rem;
    text-align: center;
    font-size: 34px;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.modal-warning {
    margin: 2rem;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: orange;
}

.container-wallet-common .label-title {
    margin: 2rem;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.container-wallet-common .sub-label-title {
    margin: 2rem;
    text-align: center;
    font-size: 16px;
}

.container-wallet-common .sub-label-title.first {
    margin-bottom: 0;
    font-weight: bold;
}

.container-wallet-common .sub-label-title.middle {
    margin-top: 0;
    margin-bottom: 0;
}

.container-wallet-common .sub-label-title.last {
    margin-top: 0;
}

.container-wallet-common .transaction {
    width: 100%;
    margin-top: 15px;
}

.container-wallet-common .presale-advert {
    margin: 17px 5px;
    padding: 20px 10px;
    background-color: rgb(44, 44, 44);
    border-radius: 15px;
    text-align: center;
    color: #ffbe00;
    font-weight: bold;
    font-size: 14px;
    animation-duration: 1500ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    --webkit-animation: blink 1500ms infinite;
}

@keyframes blink {
    from {
        color: red;
    }
    to {
        color: orange;
    }
}

@-webkit-keyframes blink {
    from {
        color: red;
    }
    to {
        color: orange;
    }
}

.container-wallet-common .send .amount-button,
.container-wallet-common .buy .amount-button {
    width: 33.3333%;
    margin: 0;
    border: 2px solid rgb(44, 44, 44);
}

.container-wallet-common .address-label {
    margin-bottom: 10px;
}

.modal-wallet ion-content {
    --background: rgb(34, 34, 34);
}

.deposit .qrcode {
    margin-top: 15px;
    display: flex;
    margin: auto;
}

.deposit .qrcode-background {
    padding: 25px;
    background-color: rgb(133, 231, 133, 0.7);
}