
/* CONTAINER */
.container-forgot {
  text-align: left;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  transform: translateY(-50px);
  background-color: white;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  bottom: 0;
}

.container-forgot h1 {
  font-size: 25px;
  line-height: 26px;
  
}

.container-forgot p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container-forgot .recover-email {
  padding-bottom: 10px;
}

.container-forgot .telegram-support {
  padding-top: 10px;
}

.container-forgot .telegram-button {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  font-weight: bold;
}

.container-forgot .login-link {
  color: black;
  margin-top: 5px;
  margin-bottom: 15px;
}

/* HEADER */

.header-forgot {
  background-color: black;
}

.header-forgot h1 {
  font-size: 60px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
}

.header-forgot h2 {
  font-size: 11px;
  text-align: center;
  margin-top: 0;
}