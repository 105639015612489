.header-collapse-condense ion-toolbar ion-searchbar {
    width: 284px;
    right: 0;
    position: absolute;
}


/* CONTENEUR */

.container-markets {
    background-color: black;
    margin-top: 100px;
}

.container-markets strong {
    font-size: 20px;
    line-height: 26px;
}

.container-markets p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

.container-markets a {
    text-decoration: none;
}


/* CONTENEUR COMMON */

.container-markets-common {
    background-color: rgb(34, 34, 34);
    margin-top: 40px;
    padding: 15px 3px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    min-height: 120vh;
    height: 100%;
    padding-bottom: 15px;
    margin: 40px 8px 0;
}

.container-markets-common .bar-buttons {
    padding: 0 10px;
}

.container-markets-common .market-text {
    font-size: 14px;
    margin-left: 15px;
    padding-top: 15px;
}

.container-markets-common .token-name {
    font-size: 24px;
    text-transform: capitalize;
}

.container-markets-common .common-buttons {
    text-align: center;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
}

.container-markets-common .common-buttons ion-card {
    margin: 0 4px;
    padding: 0 2px;
    display: inline-block;
    width: 20%;
    max-width: 90px;
    height: 70px;
    background-color: rgb(44, 44, 44);
}

.container-markets-common .common-buttons ion-card ion-card-subtitle {
    display: inline-block;
    font-size: 10px;
}

.container-markets-common .common-buttons ion-card ion-spinner {
    position: absolute;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: 5px;
    font-size: 20px;
}

.container-markets-common .common-buttons ion-card ion-icon {
    padding-top: 8px;
    font-size: 30px;
}

.container-markets-common .common-buttons ion-card ion-card-header {
    padding: 4px;
}


/* Charts */

.container-markets-charts {
    height: auto;
    background-color: transparent;
    position: relative;
}

.chartdiv-custom {
    width: 100%;
    height: 284px;
}

.chartdiv-hide-logo {
    height: 25px;
    background-color: rgb(44, 44, 44);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

g[aria-labelledby*="-title"]:nth-last-of-type(6) {
    display: none;
    height: 0;
}

g#id-22>g {
    transform: translateY(10PX);
}


/* Currencies */

.container-markets-common .common-currencies {
    padding: 2px 0;
    margin: 0 10px;
    overflow-y: hidden;
    background-color: rgb(44, 44, 44);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.container-markets-common .common-currencies .list-currencies {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.container-markets-common .common-currencies .tiny-link {
    font-size: 10px;
    margin-top: 2px;
    position: absolute;
    right: 15px;
}

.container-markets-common .common-currencies .title {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
}

.container-markets-common .common-currencies .currency ion-label {
    display: flex;
    align-items: center;
}

.container-markets-common .common-currencies .currency ion-img {
    margin-right: 10px;
}

.container-markets-common .common-currencies .currency-logo {
    width: 45px;
    height: 45px;
    display: inline-block
}

.container-markets-common .common-markets,
.container-markets-common .common-blog,
.container-markets-common .common-news {
    height: 110vh;
    overflow-y: scroll;
    padding: 0 10px 0 0;
    margin: 0 10px 0 10px;
    max-height: 110vh;
    background-color: rgb(44, 44, 44);
    padding-bottom: 55px;
}

.container-markets-common .common-news .align-item-update {
    text-align: center;
    display: block;
    padding: 15px;
}

.container-markets-common .common-markets {
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
    padding: 0;
}

.container-markets-common .common-markets .title,
.container-markets-common .common-blog .title,
.container-markets-common .common-news .title {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
}

.container-markets-common .news-button {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
}

.container-markets-common .segment-button {
    border: 1px solid rgb(44, 44, 44);
    border-radius: 10px 10px 0 0;
}