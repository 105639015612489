.header-collapse-condense ion-toolbar ion-searchbar {
    width: 284px;
    right: 0;
    position: absolute;
}


/* CONTENEUR SLIDE */

.container-slides-images {
    margin: 5px 8px 0;
}

.container-slides-images .swiper-container {
    height: 100%;
}

.container-slides-images div img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.container-slides-images div {
    border-radius: 10px;
}

.container-slides-textes {
    display: flex;
    flex-direction: row;
    margin: 0 8px;
    height: 35px;
    margin-top: 10px;
}

.container-slides-textes .swiper-slide {
    height: 40px;
    border-radius: 10px;
    padding-right: 15px;
}

.container-slides-textes ion-icon.icon-icon-list {
    position: absolute;
    right: 0;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
}

.container-slides-textes ion-icon.icon-icon-inside {
    position: relative;
    margin-left: 6px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
}

.container-slides-textes ion-label {
    font-size: 10px;
    text-align: left;
}


/* CONTENEUR */

.container-mining {
    background-color: black;
    left: 0;
    right: 0;
    top: 3px;
}

.container-mining strong {
    font-size: 20px;
    line-height: 26px;
}

.container-mining p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
    min-height: 22px;
}

.container-mining a {
    text-decoration: none;
}


/* CONTENEUR COMMON */

.container-mining-common {
    background-color: rgb(34, 34, 34);
    padding: 15px 8px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 100%;
    margin: 0 8px;
    padding-bottom: 70px;
    text-align: center;
}

.container-mining-common .compteur-text {
    font-size: 14px;
    margin-left: 15px;
}

.container-mining-common .compteur-text ion-icon {
    font-size: 24px;
}

.container-mining-common .compteur-valeur {
    font-size: 24px;
    font-weight: bold;
}

.container-mining-common .compteur-hidden {
    filter: blur(5px);
}

.container-mining-common .common-remaining {
    padding: 10px 0px 0;
    display: inline-block;
    width: calc(88% + 10px);
    height: 95px;
}

.container-mining-common .common-remaining .tiny-text {
    font-size: 10px;
}

.container-mining-common .common-remaining .progressbar-theme {
    height: 20px;
    border-radius: 5px;
    background: var(--buffer-background);
}

.container-mining-common .padding-top {
    padding-top: 10px;
}

.container-mining-common .padding-full {
    padding: 0 25px 10px;
}

.container-mining-common .common-buttons {
    text-align: center;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
}

.container-mining-common .common-buttons ion-card {
    margin: 0 3px;
    padding: 0 2px;
    display: inline-block;
    width: 17%;
    max-width: 90px;
    height: 56px;
    background-color: rgb(44, 44, 44);
}

.container-mining-common .common-buttons ion-card ion-card-subtitle {
    display: inline-block;
    font-size: 10px;
}

.container-mining-common .common-buttons ion-card ion-spinner {
    position: absolute;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: 5px;
    font-size: 20px;
}

.container-mining-common .common-buttons #miningSpinner.disabled {
    display: none;
}

.container-mining-common .common-buttons ion-card ion-icon {
    padding-top: 8px;
    font-size: 20px;
}

.container-mining-common .common-buttons ion-card ion-card-header {
    padding: 4px 0 0 0;
}


/* Charts */

.container-mining-charts {
    position: relative;
}

.container-mining-charts .chart-token-select {
    width: 90%;
    margin: auto;
}

.mining-charts-hide-logo {
    height: 20px;
    background-color: rgb(34, 34, 34);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

.chartdiv {
    width: 100%;
    height: 284px;
    position: relative;
}

g[aria-labelledby*="-title"]:nth-last-of-type(6) {
    display: none;
    height: 0;
}

g#id-22>g {
    transform: translateY(10PX);
}


/* Currencies */

.container-mining-common .common-currencies {
    padding: 0 10px 0 0;
    margin: 0 10px 0 10px;
    max-height: 70vh;
    overflow-y: scroll;
    background-color: rgb(44, 44, 44);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.container-mining-common .common-currencies .tiny-link {
    font-size: 10px;
    margin-top: 2px;
    position: absolute;
    right: 15px;
}

.container-mining-common .common-currencies .title {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
}

.container-mining-common .common-currencies .currency ion-label {
    display: flex;
    align-items: center;
}

.container-mining-common .common-currencies .currency ion-img {
    margin-right: 10px;
}

.container-mining-common .common-currencies .currency-logo {
    width: 45px;
    height: 45px;
    display: inline-block
}

/* mystery chest */
.container-myst-chest {
    padding-top: 2rem;
    min-height: 450px;
}

.container-myst-chest .chest-col {
    aspect-ratio: 1;
    height: 100%;
    border-radius: 15px;
    border: 4px solid #85e785;
    display: flex;
    background-color: antiquewhite;
    position: relative;
}

.container-myst-chest .chest-col span {
    display: flex;
    margin: auto;
    text-align: center;
    font-size: small;
    color: #8c8c8c;
}


.container-myst-chest .chest-col ion-label {
    position: absolute;
    left: calc(50% - 30px);
    margin: 0;
    bottom: 100%;
    font-size: 10px;
    background-color: #85e785;
    padding: .2rem .4rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: black;
}

.container-myst-chest .chest-col ion-button {
    position: absolute;
    left: calc(50% - 29px);
    margin: 0;
    top: 100%;
    font-size: 11px;
    color: black;
    height: 25px;
}