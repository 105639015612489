/* CONTAINER */

.container-login {
    text-align: left;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    transform: translateY(-50px);
    background-color: white;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    bottom: 0;
}

.container-login h1 {
    font-size: 25px;
    line-height: 26px;
}

.container-login p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

.container-login a {
    text-decoration: none;
    color: black;
}

.container-login .login-button {
    color: rgb(133, 231, 133);
}

.container-login .official-news {
    position: absolute;
    right: 15px;
    top: 10px;
}

.container-login .forgot-password {
    text-align: right;
    margin: 6px 2px 2px 2px;
}

.container-login .register-login {
    text-align: center;
    margin-top: 4px;
}

.container-login .help-link,
.container-login .official-news a {
    cursor: pointer;
}

.container-login ion-item {
    border-radius: 10px;
    margin: 6px 2px 2px 2px;
    height: 42px
}

.container-login .privacy-bloc {
    margin-top: 20px;
    text-align: right;
    font-size: 10px;
}

.container-login .privacy-bloc a {
    cursor: pointer;
}


/* HEADER */

.header-login {
    background-color: black;
}

.header-login h1 {
    font-size: 60px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
}

.header-login h2 {
    font-size: 11px;
}

.header-login h3 {
    font-size: 8px;
}

.header-login h2,
.header-login h3 {
    text-align: center;
    margin-top: 0;
}