/* CONTENEUR */

.container-account strong {
    font-size: 20px;
    line-height: 26px;
}

.container-account p {
    font-size: 14px;
    margin: 0;
}

.container-account .vertical-align {
    margin: auto;
}

.container-account .ion-text-wrap {
    font-size: 11px;
}

.container-account #ion-input-2-lbl,
.container-account #ion-input-3-lbl,
.container-account #ion-input-4-lbl {
    text-transform: capitalize;
    font-style: italic;
}

.container-account .full-width {
    width: 100%;
}

.container-account .full-width p {
    font-size: 12px;
}