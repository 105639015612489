.header-bar {
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #1f1f1f;
}

.header-bar .header-bar-block {
    position: relative;
    height: 66px;
}


/* Block image */

.header-bar .header-bar-img-block {
    position: absolute;
}

.header-bar .header-bar-img {
    margin-top: 2px;
    margin-left: 4px;
    height: 52px;
    width: 100%;
    border-radius: 3px;
}


/* Block label */

.header-bar .header-bar-label-block {
    display: table;
}

.header-bar .header-bar-label {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}


/* Commons */

.compteur-hidden {
    filter: blur(5px);
}